$(function () {
  var arrowsOn = true;
  $('section.benefits').each(function () {
    $slider = $(this).find('#benefits-slider');
    $nextArrow = $(this).find('.benefits__next');
    $prevArrow = $(this).find('.benefits__prev');
    $slider.slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: true,
      arrows: arrowsOn,
      appendDots: $('#benefits__slick-dot-nav-wrapper'),
      prevArrow: $prevArrow,
      nextArrow: $nextArrow,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 520,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  });
});
