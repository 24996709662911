$(function () {
  $('#slidewrap').slick({
    dots: true,
    appendDots: $('#testimonials__dots'),
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    cssEase: 'linear',
  });
});
